import { ShowProcessedImage } from "@common/ggfx-client/module/components/show-image"
import PlayVideoButton from "@components/elements/PlayVideo/PlayVideoButton"
import BedIcon from "@components/icons/BedIcon"
import LocationIcon from "@components/icons/LocationIcon"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import useDeviceMedia from "@hooks/useDeviceMedia"
import noImg from "@images/noImage.png"
import { offplanResultsSaleURL, offplanResultsURL } from "@lib/urls"
import {
  addRollWhatsapp,
  capitalize,
  getWhatsAppURL,
  getWhatsBaseURL,
  getWhatsRedirectURL,
  gtagReportConversion,
  parseHTML,
  slugToTitle,
  slugify,
} from "@lib/utils"
import clsx from "clsx"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import imageConfig from "../../../static/images/config.json"
import "./OffplanCard.scss"
import OffCurrencyPrice from "@components/CurrencyPrice/OffCurrencyPrice"
import { useGlobalStore } from "@stores/globalStore"
import GGFXImage from "@components/elements/GGFXImage"
import PhoneIconPC from "@components/icons/PhoneIconPC"
import WhatsAppIconGreen from "@components/icons/WhatsAppIconGreen"
import { RegisterInterestModal } from "@components/RegisterInterestModal/RegisterInterestModal"
import MailIcon from "@components/icons/MailIcon"
import Brightcall from "@components/Brightcall/Brightcall"
import { CurrConvertPrice, offCurrConvertPrice } from "@components/CurrencyConverter/CurrConvertPrice"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import ChevronLeftW from "@components/icons/ChevronLeftW"
import ChevronRightW from "@components/icons/ChevronRightW"
import { seoConData } from "@components/SearchResults/SearchFilters/filterOptions"
export const SliderLinkComp = ({
  children,
  url,
  slider,
  className,
  LinkComponent,
  linkProps,
  noClick,
}) => {
  LinkComponent ||= Link
  linkProps ||= {
    to: url,
  }

  const [isPointMoved, setIsPointMoved] = useState(false)
  const sliderOnSwipe = {
    onPointerDown: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(false)
    },
    onPointerMove: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(true)
    },
    onPointerUp: (e) => {
      if (isPointMoved || e.buttons === 2) return

      if (url && !noClick) {
        const isExternal = "href" in linkProps
        if (isExternal) {
          window.open(url, "_blank")
        } else {
          navigate(url)
        }
      }
    },
  }

  if (slider) {
    return (
      <div className={className} 
      // {...sliderOnSwipe}
      >
        {children}
      </div>
    )
  }

  return (
    <LinkComponent {...linkProps} className={className}>
      {children}
    </LinkComponent>
  )
}
const OffplanCard = ({
  image,
  images,
  images2,
  buldingType,
  videoURL,
  description,
  title,
  developer,
  location,
  future_launch,
  minsBeds,
  maxBeds,
  displayBedrooms,
  slug,
  community,
  slider,
  listView,
  completionYear,
  showProcessedImage,
  ggfx_results,
  strapi_id,
  price,
  notalgo,
  property,
  noContact,
  isListing,
  isFeautred
}) => {
  const { phone } = useBrandInfo()
  const { isTablet, isAboveLargeScreen } = useDeviceMedia()
  const { language } = useGlobalStore()
  const bedrooms =
  displayBedrooms !== null && displayBedrooms !== "0" ? displayBedrooms :
    // minbeds = 2, maxbeds = 4 =>  2, 3, 4
    Array.from({ length: maxBeds - minsBeds + 1 }, (_, i) => i + minsBeds).join(
      ", "
    )

  const buildingText = buldingType?.filter(type => type !== "branded").join(", ");

  const developerSlug = slugify(developer)
  let urlSlug = typeof slug === "string" ? slug : language?.value === "EN" ? slug?.en : language?.value === "RUS" ? slug?.ru : slug.en
  const propertyURL = `${offplanResultsURL + urlSlug}/`

  const LinkComponent = isTablet ? Link : Link
  const linkProps = isTablet
    ? { to: propertyURL }
    : { to: propertyURL }

  const DevComp = developerSlug ? Link : "p"

  // if completion year is in the past, then the property is ready
  const isReady = completionYear
    ? new Date(new Date(completionYear)?.setMonth(12)) < new Date()
    : false
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [shownum, setShowNum ] = useState(null)
    const toShowNumber = (id) => {
     
        setShowNum(id)
  
     
    }
    const [brignt, setBright] = useState(false)

    const { currencyType } = useGlobalStore()

    function convertToInternationalCurrencySystem(labelValue) {
      return Math.abs(Number(labelValue)) >= 1.0e+9
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        : Math.abs(Number(labelValue)) >= 1.0e+6
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        : Math.abs(Number(labelValue)) >= 1.0e+3
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"
        : Math.abs(Number(labelValue));
    }
  
    const conPrice = offCurrConvertPrice(price);
    const whatsPrice = (price && (typeof price === "number" || !isNaN(Number(price.toString().replace(/,/g, ""))))) ? currencyType?.value + convertToInternationalCurrencySystem(conPrice.replace(/,/g, "")) : price ? price : "TBA"
    const [showAll, setShowAll] = useState(false);
    const handleSlideChange = (swiper) => {
        setShowAll(true); // Set to show all images on first interaction
        swiper.update()
        if(swiper.activeIndex === 0){
          swiper.activeIndex = images2?.length
          // swiper.slideTo(images?.length, 0)
        }
    };
      const { leadSource } = useGlobalStore()
      let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
      const utmId = seoSourceName && seoSourceName?.length > 0 ? `?utm_source=${seoSourceName[0]?.id}` : ""
  return (
    <div className={clsx("offplan-card-wrap", listView && "list-view", isFeautred && "is-featured")}>
      {isFeautred &&<div className="isFeautred">Featured Project</div>}
      <div className="img-section">
        <div className="flag-section">
          {videoURL &&<PlayVideoButton videoUrl={videoURL} alternateButton />}
          {buildingText && (
            <p className="img-tag">
              <span>{buildingText}</span>
            </p>
          )}
          {future_launch && (
            <p className="img-tag tag-new">
              <span>{future_launch}</span>
            </p>
          )}
        </div>
        {completionYear &&
        <div className="flag-section ready-flag">
          <p className="img-tag">
            <span>{isReady ? "Ready" : completionYear}</span>
          </p>
        </div>}
        <SliderLinkComp
          slider={slider}
          url={propertyURL}
          LinkComponent={LinkComponent}
          linkProps={linkProps}
          className={clsx("img-section", listView && "listview-img-section")}
        >
          {listView && isAboveLargeScreen ? (
            <>
            
              <OffplanImage
                image={image}
                listView={listView}
                index={1}
                key={`offplan-img-${1}`}
                showProcessedImage={showProcessedImage}
                ggfx_results={ggfx_results}
                strapi_id={strapi_id}
                notalgo={notalgo}
              />
             
              {images?.length > 0 &&
                images
                  ?.slice(0, 2)
                  ?.map((img, index) => (
                    
                    <OffplanImage
                      image={img}
                      listView={listView}
                      index={index + 2}
                      showProcessedImage={showProcessedImage}
                      key={`offplan-img-${index + 2}`}
                      ggfx_results={ggfx_results}
                      strapi_id={strapi_id}
                      notalgo={notalgo}
                    />
                  ))}
                 
            </>
          ) : (
            image === "no-imge" ?
           <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={noImg} alt="offplan-img" id={`offplan-img-noimg`} /> :
           images2?.length > 0 ?
            <Swiper
            modules={[Navigation, Pagination]}
            // spaceBetween={50}
            // noSwiping={true}
            slidesPerView={1}
            loop={true}
            isDuplicate={true}
            navigation={{
              nextEl: '.custom-next', // Custom class for the next button
              prevEl: '.custom-prev', // Custom class for the prev button
            }}
            allowTouchMove={isListing ? true : false}
            pagination={{
              // el: ".swiper-pagination",
              clickable: true,
              dynamicBullets: true, 
                             
      }}
      onSlideChangeTransitionEnd={(swiper) => handleSlideChange(swiper)}
          >
            {showAll ? images2?.map((image, index) => (
              <SwiperSlide  
              >   <LinkComponent {...linkProps}>
              <OffplanImage
                 image={image}
                 listView={listView}
                 index={1}
                 key={`offplan-img-${1}`}
                 showProcessedImage={showProcessedImage}
                 ggfx_results={ggfx_results}
                 strapi_id={strapi_id}
                 notalgo={notalgo}
               /></LinkComponent>
               </SwiperSlide> )) : 
               images2?.slice(0, 3)?.map((image, index) => (
                 <SwiperSlide  
                 > <LinkComponent {...linkProps}>
                    <OffplanImage
                 image={image}
                 listView={listView}
                 index={1}
                 key={`offplan-img-${1}`}
                 showProcessedImage={showProcessedImage}
                 ggfx_results={ggfx_results}
                 strapi_id={strapi_id}
                 notalgo={notalgo}
               /></LinkComponent>
                     </SwiperSlide>
         ))}
              
                    <div className="custom-prev"> <ChevronLeftW size={24} /></div>
          <div className="custom-next"><ChevronRightW size={24} /></div>
           </Swiper> :  <LinkComponent {...linkProps}><img onContextMenu={(e) => e.preventDefault()} draggable="false" src={noImg} alt="offplan-img" id={`offplan-img-noimg`} /></LinkComponent>

          )}
        </SliderLinkComp>
      </div>
      <div className="content-section">
        <LinkComponent {...linkProps} className="title">
          {title} {community ? `at ${slugToTitle(community)}` : ""}
        </LinkComponent>
        {developer && (
          <DevComp
            to={`${offplanResultsSaleURL}developed-by-${developerSlug}/`}
            className="developer"
          >
            by <span>{slugToTitle(developer)}</span>
          </DevComp>
        )}
        
        {(price && (typeof price === "number" || !isNaN(Number(price.toString().replace(/,/g, ""))))) ? (
        <div className="price">
          <span>Starting Price </span>
          <OffCurrencyPrice price={price} />
        </div>
      ) : (
        <div className="price">
          <span>Starting Price </span>
          {price ? price : "TBA"}
        </div>
      )}
        <div className="more-info">
          <p className="location">
            <LocationIcon blue />
            <span>{location}</span>
          </p>         
          {typeof bedrooms === "string" ? 
          <p className="beds">
          <BedIcon />
          <span>{bedrooms}</span>
        </p> :
          bedrooms > 0 ?
          <p className="beds">
            <BedIcon />
            <span>{bedrooms}</span>
          </p> : null}
        </div>
        {description && (
          <p className="description">
            <span>{parseHTML(`${typeof description === "string" ? description?.slice(0, 170) : language?.value === "EN" ? description?.en?.slice(0, 170) : language?.value === "RUS" ? description?.ru?.slice(0, 170) : description?.en?.slice(0, 170)}...`)}</span>
            <LinkComponent {...linkProps} className="read-more-text">
              more
            </LinkComponent>
          </p>
        )}
        <RegisterInterestModal
        offplan={property}
        show={show}
        handleClose={handleClose}
        propertyURL={process.env.GATSBY_SITE_URL+propertyURL}
      />
         {!noContact && (
          <div className={
            // isAboveLargeScreen && !listView && isListing && strapi_id === shownum ? "cta-section svg-fix" : 
            "cta-section"
            }>
            <button
              // to={bookAViewingURL}
              onClick={handleShow}
              className="property-cta email"
             
            >
              <MailIcon color="black" />
              {/* {isAboveLargeScreen && !listView && isListing && strapi_id === shownum ? null : */}
              <span>Email</span>
              {/* }  */}
            </button>
            <a
              // href={`tel:${phone}`}
              href="javascript:void(0);"
              className="property-cta"
              onClick={() => 
                {
                  // if (isAboveLargeScreen && isListing) {
                  //   toShowNumber(strapi_id); // Call toShowNumber when condition is met
                  //   gtagReportConversion(`tel:${phone}`)
                  // }
                  // else {
                  //   gtagReportConversion(`tel:${phone}`)
                  // }
                  gtagReportConversion(`tel:${phone}`)
                  setBright(true)
                }
                 }
            >
               <PhoneIconPC />
               <span>Call</span>
               {/* {isAboveLargeScreen && isListing && strapi_id === shownum ? <span>{phone ? phone?.replace(/ /g, '') : "97143233609"}</span> :<span>Call</span>} */}
            </a>
            
            <a
              href={getWhatsRedirectURL(
                "971505389860",
                `Hello Provident,\n\nI would like to know more about this Off Plan Project:\n\n• Project Name: ${title}\n• Project Location: ${location}\n• Developer: ${slugToTitle(developer)}\n• Type: ${capitalize(buildingText)}\n• Starting Price:  ${whatsPrice}\n• Link: ${process.env.GATSBY_SITE_URL + propertyURL+utmId}\n\nModifying this message will prevent it from being sent to the agent.`
              )}
              target="_blank"
              className="property-cta whats"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <WhatsAppIconGreen outline green />
              {/* {isAboveLargeScreen && !listView && isListing && strapi_id === shownum ? null : */}
              <span>WhatsApp</span>
              {/* } */}
            </a>
          </div>
        )}
      </div>
      {brignt && <Brightcall setBright={setBright} developer={developer} property={property} propertyURL={propertyURL} />}
    </div>
  )
}

const OffplanImage = ({ image, listView, index, showProcessedImage, ggfx_results, strapi_id, notalgo }) => {
  let Comp =  <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={image} alt="offplan-img" id={`offplan-img-${index}`} />
  if(notalgo){
    Comp =  <GGFXImage
  ImageSrc={image}
  altText={"offplan-img"}
  imagetransforms={
    ggfx_results
  }
  renderer="srcSet"
  imagename="off-plan.images.tileimg"
  strapiID={strapi_id}
/>
  }
  else if (image) {
    if (showProcessedImage) {
      Comp = (
        <ShowProcessedImage
          images={image}
          attr={{
            alt: "offplan-img",
            id: `property-image-${index}`,
          }}
          transforms={imageConfig.property.searchResults.sizes}
        />
      )
    }
   
  } else {
    Comp = <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={noImg} alt="offplan-img" id={`offplan-img-${index}`} />
  }

  return (
    <div className={clsx("img-zoom", listView && "listview-img")}>{Comp}</div>
  )
}

export default OffplanCard