import BannerLanding from "@components/Banner/BannerLanding"
import ContentAndStats from "@components/ContentAndStats/ContentAndStats"
import FeaturedPropertiesSlider from "@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import Layout from "@components/Layout/Layout"
import OfficeLocation from "@components/OfficeLocation/OfficeLocation"
import OurServices from "@components/OurServices/OurServices"
import TeamsSlider from "@components/TeamsSlider/TeamsSlider"
import Seo from "@components/seo"
import { contactURL } from "@lib/urls"
import { graphql } from "gatsby"
import React, { useEffect } from "react"

function OfficeDetailTemplate({ data }) {
  const getQuery = () => {
    if (typeof window !== "undefined") {
      return new URLSearchParams(window.location.search)
    }
    return new URLSearchParams()
  }
  const queryParams = getQuery()
  const paramValue = queryParams.get("popup")
  
  const office = data?.strapiOffice
  const modules = office?.modules
  const {
    ggfx_results,
    strapi_id: strapiID,
    title,
    bg_image,
    phone,
    address,
    opening_hours,
  } = office

  const banner = {
    title,
    description: {
      data: {
        description: `<p>${address}</p>`,
      },
    },
    banner_image: bg_image,
    ctas: [
      {
        cta_label: "Contact Office",
        custom_link: "$office-contact-popup",
        icon: "mail-white",
        id: "contact-office-btn",
      },
      {
        cta_label: phone,
        custom_link: `tel:${phone}`,
        icon: "phone-blue",
        id: "phone-office-btn",
      },
    ],
    cta_text: {
      text: opening_hours,
      noCta: {
        cta_label: "Opening Hours",
      },
    },
  }

  const breadcrumbURLs = [
    {
      url: contactURL,
      label: "Contact",
    },
    {
      url: "",
      label: title,
    },
  ]

  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);

  return (
    <Layout
      pageData={{
        page_class: "office-detail-page",
        layout: "office-detail",
      }}
    >
      <BannerLanding
        bannerData={banner}
        ggfx_results={ggfx_results}
        strapiID={strapiID}
        breadcrumbURLs={breadcrumbURLs}
        dontShowCTAIcon
        office={office}
        isOffice
        paramValue={paramValue}
      />
      {modules?.map((module) => (
        <React.Fragment key={module.id}>
          {module?.strapi_component === "modules.featured-prop-slider" && (
            <FeaturedPropertiesSlider module={module} />
          )}
          {module?.strapi_component === "modules.content-and-stats" && (
            <ContentAndStats module={module} />
          )}
          {module?.strapi_component === "modules.office-location" && (
            <OfficeLocation module={module} />
          )}

          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "team_slider" && (
              <TeamsSlider module={module} />
            )}
          {module?.strapi_component === "modules.our-services" && (
            <OurServices
              module={module}
              ggfx_results={ggfx_results}
              strapiID={strapiID}
            />
          )}
        </React.Fragment>
      ))}
    </Layout>
  )
}

export default OfficeDetailTemplate

export const Head = ({ data }) => {
  const office = data?.strapiOffice

  return (
    <Seo
      title={office?.seo?.metaTitle || `${office?.title} | Office`}
      description={
        office?.seo?.metaDescription ||
        `Find out more about ${office?.title} office.`
      }
      customCanonical={office?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    strapiOffice(id: { eq: $id }) {
      ...officeFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_CONTENT_AND_STATS {
          ...contentAndStats
        }
        ... on STRAPI__COMPONENT_MODULES_FEATURED_PROP_SLIDER {
          ...featuredProperties
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
        ... on STRAPI__COMPONENT_MODULES_OFFICE_LOCATION {
          ...officeLocation
        }
        ... on STRAPI__COMPONENT_MODULES_OUR_SERVICES {
          ...ourServices
        }
      }
    }
  }
`
