import React from "react"
import "./TeamCard.scss"
import Animation from "@components/elements/Animation"
import { isEmpty } from "lodash"
import { Link } from "gatsby"
import { teamsURL } from "@lib/urls"
import GGFXImage from "@components/elements/GGFXImage"
import PhoneIcon from "@components/icons/PhoneIcon"
import { useGlobalStore } from "@stores/globalStore"
import { seoConData } from "@components/SearchResults/SearchFilters/filterOptions"
import { addRollWhatsapp, gtagReportConversion } from "@lib/utils"
import WhatsAppIconGreen from "@components/icons/WhatsAppIconGreen"
import PhoneIconPC from "@components/icons/PhoneIconPC"
import TeamContactModalWithTrigger from "@components/TeamContactModal/TeamContactModal"
import MailIcon from "@components/icons/MailIcon"

const TeamCard = ({ team, noAnimation, isTeam }) => {
  if (isEmpty(team)) return null

  const { name, designation, image, slug, ggfx_results, strapi_id, languages, phone, email, negotiator_mapping, extra } = team

  const { leadSource, utm_platform } = useGlobalStore()
  let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)

  return (
    <Animation
      wrapperClassName="team-card-wrap"
      className={isTeam ? "team-card border-style" : "team-card"}
      noAnimation={noAnimation}
    >
      <Link to={`${teamsURL + slug}/`} className="img-section img-zoom">
        <GGFXImage
          key={strapi_id}
          ImageSrc={image}
          altText={name}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename={isTeam ? "team.image.card_img" : "team.image.tile_img"}
          strapiID={strapi_id}
        />
      </Link>
      {isTeam ?
        <div className="team-content">
           <p className="name">{name}</p>
           <p className="designation">{designation}</p>
          {languages?.strapi_json_value?.length > 0 && (
            <p className="languages">
              Languages: <span>{languages.strapi_json_value.join(", ")}</span>
            </p>
          )}
          <div className="cta-section">
            {email &&
          <TeamContactModalWithTrigger
            team={team}
            className="btnn bt"
          >
             <MailIcon color="black" />
            <span>Email</span>
           
          </TeamContactModalWithTrigger>}
          <div className="hypen"/>
            {phone &&
              <a href={`tel:${phone}`} onClick={() => gtagReportConversion(`tel:${phone}`)} className="btnn bt">
                <PhoneIcon color={"#000"} />
                <span>Call</span>
              </a>}
              <div className="hypen"/>
            {phone && <a target="_blank"
              href={`https://wa.dxb-freehold.com/inquire?phone=971505389860&email=${email}&resp_phone=${phone?.replace(/\D/g, "")}&text=${encodeURIComponent(`Hello Provident,\n\nI would like to contact ${name}\n\n• Email: ${email}\n• Link: ${process.env.GATSBY_SITE_URL + teamsURL + slug}\n• ID: ${negotiator_mapping}\n\nModifying this message will prevent it from being sent to the agent.`)}&resp_name=${encodeURIComponent(name)}&utm_source=${encodeURIComponent(seoSourceName && seoSourceName?.length > 0 && seoSourceName[0]?.name)}&event_type=Whatsapp Click&utm_platform=${utm_platform? utm_platform : `""`}`}
              className="btnn bt"
              onClick={() => addRollWhatsapp()}
            >
              <WhatsAppIconGreen outline green />
              <span>WhatsApp</span>
            </a>}
          </div>
        </div>
      : 
      <>
      <p className="name">{name}</p>
      <p className="designation">{designation}</p>
      </>
      }
    </Animation>
  )
}

export default TeamCard
